<template>
  <div class="container">
    <div class="duty-statistics">
      <div class="statistics-top">
        <div class="top-tit-wrap">
          <span v-if="executiveArr.length > 1">
            <el-select class="form-row" v-model="deptId" placeholder="请选择部门">
              <el-option v-for="item in executiveArr" :key="item.departmentId" :label="item.department" :value="item.departmentId" @click.native="changeDept(item)"></el-option>
            </el-select>
          </span>
          <span class="top-tit top-ml">{{deptName}}统计报表</span>
        </div>
        <el-button :class="{'el-button--primary': active === 1}" @click="changeActive(1)">综合统计报表</el-button>
        <el-button class="top-ml" :class="{'el-button--primary': active === 2}" @click="changeActive(2)">月统计报表</el-button>
        <el-date-picker class="top-ml" v-if="active === 2" v-model="date" type="month" placeholder="选择月" value-format="yyyy-MM" @change="changeTable2" :clearable="false"></el-date-picker>
      </div>
      <div>
        <ul class="el-row box-list">
          <li class="el-col el-col-7 box-item">
            <div class="box-content item-leave">
              <div class="leave-top">
                <div class="box-icon">
                  <img :src="require('@/assets/images/dutyManagement/icon-leave1.png')" alt="">
                </div>
                <div class="box-text" v-if="active === 1">
                  <div class="text-number">{{leaveData1.leaveDuration ? leaveData1.leaveDuration : 0}}</div>
                  <div class="text-tip">请假</div>
                </div>
                <div class="box-text" v-if="active === 2">
                  <div class="text-number">{{setNoZeroMonth(month)}}<span class="text-unit">月</span></div>
                  <div class="text-tip">{{year}}年</div>
                </div>
              </div>
              <div class="leave-bottom" v-if="active === 1">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData1.leaveDuration ? leaveData1.leaveDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData1.leaveNum ? leaveData1.leaveNum : 0}}次</div>
              </div>
              <div class="leave-bottom" v-if="active === 2">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData2.leaveDuration ? leaveData2.leaveDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData2.leaveNum ? leaveData2.leaveNum : 0}}次</div>
              </div>
            </div>
          </li>
          <li class="el-col el-col-7 box-item">
            <div class="box-content item-leave2">
              <div class="leave-top">
                <div class="box-icon">
                  <img :src="require('@/assets/images/dutyManagement/icon-leave2.png')" alt="">
                </div>
                <div class="box-text" v-if="active === 1">
                  <div class="text-number">{{leaveData1.travelDuration ? leaveData1.travelDuration : 0}}</div>
                  <div class="text-tip">出差</div>
                </div>
                <div class="box-text" v-if="active === 2">
                  <div class="text-number">{{setNoZeroMonth(month)}}<span class="text-unit">月</span></div>
                  <div class="text-tip">{{year}}年</div>
                </div>
              </div>
              <div class="leave-bottom" v-if="active === 1">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData1.travelDuration ? leaveData1.travelDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData1.travelNum ? leaveData1.travelNum : 0}}次</div>
              </div>
              <div class="leave-bottom" v-if="active === 2">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData2.travelDuration ? leaveData2.travelDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData2.travelNum ? leaveData2.travelNum : 0}}次</div>
              </div>
            </div>
          </li>
          <li class="el-col el-col-7 box-item">
            <div class="box-content item-leave3">
              <div class="leave-top">
                <div class="box-icon">
                  <img :src="require('@/assets/images/dutyManagement/icon-leave3.png')" alt="">
                </div>
                <div class="box-text" v-if="active === 1">
                  <div class="text-number">{{leaveData1.egressDuration ? leaveData1.egressDuration : 0}}</div>
                  <div class="text-tip">外出</div>
                </div>
                <div class="box-text" v-if="active === 2">
                  <div class="text-number">{{setNoZeroMonth(month)}}<span class="text-unit">月</span></div>
                  <div class="text-tip">{{year}}年</div>
                </div>
              </div>
              <div class="leave-bottom" v-if="active === 1">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData1.egressDuration ? leaveData1.egressDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData1.egressNum ? leaveData1.egressNum : 0}}次</div>
              </div>
              <div class="leave-bottom" v-if="active === 2">
                <div class="leave-date"><span>累计/</span>总时长：{{leaveData2.egressDuration ? leaveData2.egressDuration : 0}}天</div>
                <div class="leave-times">总次数：{{leaveData2.egressNum ? leaveData2.egressNum : 0}}次</div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 综合统计报表 table -->
        <div class="statistics-table"  v-if="active === 1">
          <el-button type="primary" :disabled="!downloadBtn" @click="downloadExcel(1)">导出报表</el-button>
          <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="realName" label="姓名"></el-table-column>
            <el-table-column align="center" label="累计请假次数/时长">
              <template slot-scope="scope">
                <span>{{ scope.row.leaveNum ? scope.row.leaveNum : 0 }}次 /</span>
                <span>{{ scope.row.leaveDuration ? scope.row.leaveDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="累计出差次数/时长">
              <template slot-scope="scope">
                <span>{{ scope.row.travelNum ? scope.row.travelNum : 0 }}次 /</span>
                <span>{{ scope.row.travelDuration ? scope.row.travelDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="累计外出次数/时长">
              <template slot-scope="scope">
                <span>{{ scope.row.egressNum ? scope.row.egressNum : 0 }}次 /</span>
                <span>{{ scope.row.egressDuration ? scope.row.egressDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作" width="150">
              <template slot-scope="scope">
                <el-button @click="downloadRow(scope.row, 1)" type="text" size="small">下载记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 月统计报表 table -->
        <div class="statistics-table" v-if="active === 2">
          <el-button type="primary" :disabled="!downloadBtn2" @click="downloadExcel(2)">导出报表</el-button>
          <el-table :data="tableData2" stripe style="width: 100%" @selection-change="handleSelectionChange2">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="realName" label="姓名"></el-table-column>
            <el-table-column align="center" label="请假次数/时长（月）">
              <template slot-scope="scope">
                <span>{{ scope.row.leaveNum ? scope.row.leaveNum : 0 }}次 /</span>
                <span>{{ scope.row.leaveDuration ? scope.row.leaveDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="出差次数/时长（月）">
              <template slot-scope="scope">
                <span>{{ scope.row.travelNum ? scope.row.travelNum : 0 }}次 /</span>
                <span>{{ scope.row.travelDuration ? scope.row.travelDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="外出次数/时长（月）">
              <template slot-scope="scope">
                <span>{{ scope.row.egressNum ? scope.row.egressNum : 0 }}次 /</span>
                <span>{{ scope.row.egressDuration ? scope.row.egressDuration : 0 }}天</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作" width="150">
              <template slot-scope="scope">
                <el-button @click="downloadRow(scope.row, 2)" type="text" size="small">下载记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/utils/config';
import { downlodFile } from '@/utils/utils';// 解析excel插件

export default {
  data() {
    return {
      active: 1, // 1、综合统计报表，2、月统计报表
      tableData: [],
      tableData2: [],
      downloadBtn: false, // 表一导出报表
      userIds1: [], // 表一选中项
      downloadBtn2: false, // 表二导出报表
      userIds2: [], // 表二选中项
      date: '', // 当前日期前一个月
      year: '', // 选择日期年份
      month: '', // 选择日期月份
      deptName: '', // 部门名称
      deptId: '', // 部门id
      leaveData1: {}, // 累计统计详情
      leaveData2: {}, // 累计统计详情
      firstChange: false, // 是否第一次点击 - 月统计报表
      executiveArr: [], // 部门主管list
      isExecutive: 0 // 是否是部门主管, 0 - 否 1 - 是
    };
  },
  created() {
    const date = new Date();
    this.year = date.getFullYear();
    if (date.getMonth() !== 0) {
      this.month = date.getMonth() > 9 ? date.getMonth() : '0' + date.getMonth();
    } else {
      this.year -= 1;
      this.month = 12;
    }
    this.date = this.year + '-' + this.month;
    this.getDeptId();
  },
  methods: {
    changeActive(type){
      this.active = type;
      (!this.firstChange) ? this.getListData2() : '';
      this.firstChange = true;
    },
    // 选择部门
    changeDept(val) {
      this.deptId = val.departmentId;
      this.deptName = val.department;
      // this.getExecutive();
      this.active = 1;
      this.firstChange = false;
      this.getListData();
    },
    // 获取部门id
    async getDeptId() {
      const data = await this.$fetchData.fetchGet({}, '/api/attendance/backend/approval/department');
      if (data.code === '200') {
        if (data.result && data.result.length > 0) {
          this.executiveArr = data.result;
          this.deptId = this.executiveArr[0].departmentId;
          this.deptName = this.executiveArr[0].department;
          // this.getExecutive();
          this.getListData();
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    // 是否是部门主管
    async getExecutive() {
      const data = await this.$fetchData.fetchGet({}, '/api/attendance/backend/approval/supervisor/' + this.deptId);
      if (data.code === '200') {
        if (data.result && data.result.isExecutive === 1) {
          this.isExecutive = 1;
          this.getListData();
        } else {
          this.isExecutive = 0;
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    // 获取列表数据
    async getListData() {
      const params = {
        departmentId: this.deptId,  // 部门id
        statisticDate: '', // 统计时间
        userIds: [] // 下载导出 - 用户Id
      };

      const data = await this.$fetchData.fetchPost(params, '/api/attendance/backend/approval/statistics', 'json');
      if (data.code === '200') {
        if (data.result && data.result.childList) {
          this.leaveData1 = data.result;
          this.tableData = data.result.childList;
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    async getListData2() {
      const params = {
        departmentId: this.deptId,  // 部门id
        statisticDate: this.date, // 统计时间
        userIds: [] // 下载导出 - 用户Id
      };

      const data = await this.$fetchData.fetchPost(params, '/api/attendance/backend/approval/statistics', 'json');
      if (data.code === '200') {
        if (data.result && data.result.childList) {
          this.leaveData2 = data.result;
          this.tableData2 = data.result.childList;
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    // 选择日期
    changeTable2(val) {
      this.date = val;
      const valArr = val.split('-');
      this.year = valArr[0];
      this.month = valArr[1];
      this.getListData2();
    },
    setNoZeroMonth(val) {
      return val > 9 ? val : val.slice(1);
    },
    // 表格选择项
    handleSelectionChange(val) {
      this.userIds1 = val.map(item => {
        return item.createdUser;
      });
      if (val.length > 0) {
        this.downloadBtn = true;
      } else {
        this.downloadBtn = false;
      }
    },
    handleSelectionChange2(val) {
      this.userIds2 = val.map(item => {
        return item.createdUser;
      });
      if (val.length > 0) {
        this.downloadBtn2 = true;
      } else {
        this.downloadBtn2 = false;
      }
    },
    // 导出报表
    async downloadExcel(type) {
      const date = type === 1 ? '' : this.date;
      const userIdsArr = type === 1 ? this.userIds1 : this.userIds2;
      const name =  type === 1 ? '综合统计报表' : '月统计报表';
      const params = {
        departmentId: this.deptId,
        statisticDate: date,
        userIds: userIdsArr
      };
      
      const data = await this.$fetchData.fetchPost(params, '/api/attendance/backend/approval/download', 'arraybuffer');
      
      downlodFile(data, name + '.xlsx');
    },
    // 下载记录
    async downloadRow(ele, type) {
      const date = type === 1 ? '' : this.date;
      const userIdsArr = [];
      userIdsArr[0] = ele.createdUser;
      const name =  type === 1 ? '综合统计--个人报表' : '月统计--个人报表';
      const params = {
        departmentId: this.deptId,
        statisticDate: date,
        userIds: userIdsArr
      };
      
      const data = await this.$fetchData.fetchPost(params, '/api/attendance/backend/approval/download', 'arraybuffer');
      
      downlodFile(data, name + '.xlsx');
    }
  }
};
</script>

<style lang="less" scoped>
.duty-statistics{
  .statistics-top{
    padding: 0 10px 20px;
    border-bottom: 1px solid #F0F0F0;
    .top-tit-wrap{
      margin: 15px auto;
      .top-tit{
        font-size:20px;
        font-weight: bold;
        line-height: 30px;
      }
    }
    .top-ml{
      margin-left: 25px;
    }
  }
  .executive-tip{
    padding: 30px 15px;
  }
  .box-list{
    .box-item{
      padding: 30px 15px;
      .box-content{
        width: 270px;
        margin: 0 auto;
        height: 142px;
        background-color: #4EBBC2;
        border-radius: 6px;
        &.item-leave2{
          background-color: #6F99DE;
        }
        &.item-leave3{
          background-color: #FCB456;
        }
        .leave-top{
          display: flex;
          padding: 20px 30px;
          color: #FFFFFF;
          .box-icon{
            width: 60px;
            height: 60px;
            img{
              width: 60px;
              height: 60px;
            }
          }
          .box-text{
            flex: 1;
            text-align: right;
            height: 60px;
            padding-right: 10px;
            .text-number{
              font-size: 38px;
              height: 40px;
              line-height: 40px;
              .text-unit{
                font-size: 14px;
              }
            }
            .text-tip{
              font-size: 14px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
        .leave-bottom{
          font-size: 14px;
          color: #ffffff;
          height:42px;
          line-height: 48px;
          background:rgba(0,0,0,0.15);
          border-radius: 0 0 6px 6px;
          padding: 0 15px;
          display: flex;
          .leave-date{
            span{
              position: relative;
              top: -5px;
            }
          }
          .leave-times{
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
  .statistics-table{
    padding: 0 10px;
  }
}
</style>